// Libs
import React from 'react';
import { navigate } from 'gatsby';

// Components
import SEO from '../../components/seo';
import HeaderPetshopRegister from '../../components/HeaderPetshopRegister';

import RegisterSuccess from '../../components/RegisterSuccess';
import PetshopService from '../../services/Petshop';


function SuccessRegister() {
  const handleSubmitForm = async () => {
    //window.sessionStorage.setItem('@iPetis:user', JSON.stringify(data))
    const data = window.sessionStorage.getItem('@login')
    const user = JSON.parse(data)
    const http = new PetshopService()

    const response = await http.session(user.email, user.password)
    const { token } = response.data;

    const dashboardUrl = process.env.GATSBY_ENV == 'DEV' ? 
    `https://devapp.ipetis.com.br/login/${token.token}` : 
    `https://app.animaupet.com.br/login/${token.token}`
    window.open(dashboardUrl, '_blank').focus();
  };
  return (
    <>
      <SEO title="Cadastro finalizado." />

      <HeaderPetshopRegister percentProgressLine="100%" />
      <RegisterSuccess handleSubmitForm={handleSubmitForm} />
    </>
  );
}

export default SuccessRegister;
