import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  background: ${lighten(1, '#e5e5e5')};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 100px);
  padding: 32px;
`;

export const Text = styled.div`
  p {
    text-align: justify;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #a5a9b0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 608px;
  margin-top: 50px;

  div + div {
    margin-top: 16px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #747a85;
    letter-spacing: 0.0015em;
    margin-bottom: 16px;
  }

  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.0015em;
    color: #00a459;
    margin-bottom: 50px;
    text-align: center;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 62px;
  line-height: 66px;
  letter-spacing: -0.01em;
  color: #1d211c;
  margin-bottom: 16px;
  text-align: center;
`;
