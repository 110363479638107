// Libs
import React from 'react';

// Components
import Layout from '../layout';
import Button from '../Button';

// Styles
import { Container, Text, Content, Title } from './styles';

function RegisterSuccess({ handleSubmitForm }) {
  return (
    <Layout>
      <Container>
        <Content>
          <h3>Cadastro efetuado com sucesso!</h3>
          <Title>Bem-vindo à animAU</Title>
          <Text>
            <p>Acesse agora, personalize a sua loja e adicione seus produtos e serviços.</p>

            <p
              style={{
                fontSize: '26px',
                color: '#747A85',
                marginTop: '16px',
                marginBottom: '50px',
                textAlign: 'center'
              }}
            >
              Boas vendas!
            </p>
          </Text>
          <Button height="55" style={{ marginTop: '16px' }} onClick={handleSubmitForm}>
            Continuar
          </Button>
        </Content>
      </Container>
    </Layout>
  );
}

export default RegisterSuccess;
